.cmsBnr img {
   width: 100%;
   height: 446px;
   height: clamp(250px,36vw,446px);
   object-fit: cover;
   object-position: center;
   mix-blend-mode: luminosity;
}

.cmsBnr {
   position: relative;
   margin-bottom: 14px;
}

.cmsBnrTx {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: clamp(90%,56%,56%);
   text-align: center;
   height: 65%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.cmsBnrTx::before {
   content: '';
   opacity: 0.800000011920929;
   background: linear-gradient(149deg, #FF724D 0%, #6D36E1 100%);
   mix-blend-mode: color;
   position: absolute;
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   pointer-events: none;
}

.cmsBnrTx h2 {
   font-size: clamp(30px,6vw,50px);
   margin: 0px;
   line-height: 1.5;
   font-family: 'dinpro-medium';
}

.cmsPg
{
   color: #ffffff;
   h1,h2,h3,h4,h5,h6
   {
      margin-top: 8px;
      margin-bottom: 0px;
      line-height: 2.5;
      font-family: 'dinpro-medium';
   }
   p
   {
      font-size: 15px;
      margin-bottom: 0px;
      line-height: 1.8;
      color: #ECECEC;
   }
   br
   {
      content: '';
      height: 12px;
      display: block;
   }
   ul
   {
      padding-left: 40px;
      list-style-type: disc;
      li
      {
         line-height: 1.8;
         font-size: 15px;
      }
   }
}