.prfPg {
   background-color: #0B0B0B;

   @media only screen and (min-width: 1350px) {
      .container1 {
         max-width: 1170px !important;
      }
   }

   padding-top: 77px;
}


// Header Content Start
.indHdv2 .indHd .navbar {
   margin-top: 0px !important;
}

.indHdv2 .indHd {
   padding: 8.5px 0px;
}

// Header Content End


// Banner Content Start
.prfBnr {
   background-image: url('./assets/banner.jpg');
   background-size: cover;
   background-position: center;
   padding: 67px 0px 10px;
}

.prfDtIgTx {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   justify-content: center;
}

.prfDtIg {
   margin-right: 1rem;
}

.prfDtIg img {
   width: 100px;
   height: 100px;
   object-fit: cover;
   border-radius: 50%;
}

.prfDtTxNm {
   font-weight: 700;
   font-size: 18px;
   line-height: normal;
   margin-bottom: 10px;
}

.prfDtTxAdr {
   display: flex;
   align-items: center;
   padding-bottom: 8px;
}

.prfDtTxAdr span {
   font-size: 15px;
   font-weight: 500;
   line-height: normal;
}

.prfDtTxAdr .btn {
   font-size: 0px;
   padding: 0px 4px;
}

.prfDtTx p {
   margin: 0px;
   font-weight: 400;
   line-height: 22px;
   color: #ffffff;
   font-size: 15px;
   max-width: 100vh;
}

.prfBnrR {
   align-items: center;
}

.prfDtAsVlNb {
   font-size: 40px;
   font-weight: 700;
   color: #ffffff;
   text-align: center;
   line-height: normal;
   margin-bottom: 4px;
}

.prfDtAsVlLb {
   text-align: center;
   color: rgba(255, 255, 255, 0.70);
   font-weight: 400;
   line-height: 26px;
   font-size: 18px;
}

.prfDtAsVlC:first-child::after {
   content: '';
   opacity: 0.5;
   background: #ffffff;
   width: 1px;
   height: 30px;
   position: absolute;
   top: 50%;
   right: 0px;
   transform: translate(0px, -50%);
}

.prfDtAsVlR {
   justify-content: center;
}

.prfBnrScl {
   text-align: right;
   padding: 1rem 0px;
}

.prfBnrScl a {
   display: inline-flex;
   width: 24px;
   height: 24px;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   background: rgba(255, 255, 255, 0.26);
   margin-left: 10px;
}

.prfBnrC {
   padding-top: 1rem;
   padding-bottom: 1rem;
}

@media only screen and (max-width: 991px) {
   .prfBnr {
      padding-top: 10px;
      background-position-x: right;
   }

   .prfBnrScl {
      text-align: center;
   }

   .prfBnrScl a {
      margin-right: 8px;
   }
}

@media only screen and (max-width: 767px) {
   .prfDtAsVlNb {
      font-size: 30px;
   }
}

@media only screen and (max-width: 575px) {
   .prfDtIgTx {
      flex-wrap: wrap;
   }

   .prfDtIg {
      flex: 0 0 100%;
      margin: 0px 0px 1rem;
      text-align: center;
   }

   .prfDtTx {
      text-align: center;
   }

   .prfDtTxAdr {
      justify-content: center;
   }

   .prfDtAsVlNb {
      font-size: 24px;
   }

   .prfDtAsVlLb {
      font-size: 16px;
   }
}

@media only screen and (max-width: 349px) {
   .prfDtAsVlNb {
      font-size: 20px;
      margin-bottom: 2px;
   }

   .prfDtAsVlLb {
      font-size: 14px;
   }
}

// Banner Content End



// Profile Tab Content Start
.prfTabs {
   padding: 10px 0px;
}

.prfTabs .nav-tabs {
   border-bottom: 0px;
   display: inline-flex;
   background-color: #121113;
   padding: 5px;
}

.prfTabs .nav-tabs .nav-link {
   border: 0px;
   font-size: 16px;
   padding: 10px 12px;
   color: rgba(255, 255, 255, 0.6);
   font-weight: 500;
   cursor: pointer;
   border-radius: 0px;
   line-height: normal;
}

.prfTabs .nav-tabs .nav-link.active {
   background-color: #000000;
   color: #ffffff;
}

.prfTabs .nav-tabs .nav-link.active span {
   background: var(--linear, linear-gradient(149deg, #FF724D 0%, #6D36E1 100%));
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 600;
}

// Profile Tab Content End



// Profile Table Content Start
.prfTbl {
   overflow: auto;
   padding-bottom: 12px;
}

.prfTbl table {
   background-color: #121113;
   margin-bottom: 0px;
   white-space: nowrap;
   width: 100%;
}

.prfTbl table th {
   border: 0px;
   color: #959595;
   font-weight: 500;
   padding: 15px 36px;
   line-height: normal;
   background-color: #1B1A1D;
}

.prfTbl table td {
   border-top: 0px;
   padding: 10px 36px;
   font-weight: 500;
   font-size: 16px;
   color: #ffffff;
   line-height: normal;
   vertical-align: middle;
}

.prfTbl table td div {
   padding: 2px 0px;
}

.tdTx1 {
   font-size: 14px;
   color: rgba(255, 255, 255, 0.6);
}

.tdAdr {
   display: flex;
   align-items: center;
}

.tdAdr .btn {
   font-size: 0px;
   padding: 0px 4px 0px 0px;
}

.tdAdr span {
   font-size: 15px;
   color: rgba(255, 255, 255, 0.8);
}

.prfTbl table td.tdDt {
   font-size: 14px;
}

.prfTbl table th:last-child,
.prfTbl table td:last-child {
   text-align: right;
}

.prfTbl table tr:last-child td {
   padding-bottom: 20px;
}





.prfTblEnPg {
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   padding-bottom: 1rem;
}

.prfTblEnTx {
   font-size: 14px;
   color: rgba(255, 255, 255, 0.50);
   margin-bottom: 1rem;
}

.prfTblEnTx span {
   color: #ffffff;
}

.prfTblEnPg ul {
   margin-bottom: 1rem;
   flex-wrap: wrap;
   gap: 10px 0px;
}

.prfTblEnPg .page-link {
   border-radius: 0px;
   font-size: 14px;
   font-weight: 500;
   background-color: transparent;
   border: 0px;
   color: rgba(255, 255, 255, 0.5);
}

.prfTblEnPg .page-link:focus {
   box-shadow: none;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
   border-radius: 0px;
   background-color: #292829;
}

.prfTblEnPg .page-item.active .page-link {
   color: #ffffff;
   background-color: transparent;
}

@media only screen and (max-width: 575px) {
   .prfTbl {
      padding-bottom: 1rem;
   }

   .prfTblEnPg {
      justify-content: center;
   }

   .prfTblEnTx {
      width: 100%;
      text-align: center;
   }
}

// Profile Table Content End

// Profile Edit Popup Content Start
.edtPrf {
   padding: 20px 16px;

   @media only screen and (max-width: 399px) {
      padding: 20px 8px;
   }
}

.edtPrf h6 {
   font-weight: 600;
   font-size: 18px;
   color: #ffffff;
   text-align: center;
   padding-bottom: 1rem;
}

.edtPrfFrm {
   padding: 10px 0px 0px;
}

.edtPrfChIgLb {
   position: relative;
   cursor: pointer;
}

.edtPrfIg {
   display: inline-block;
}

.edtPrfIg img {
   border-radius: 50%;
   width: 100px;
   height: 100px;
   object-fit: cover;
}

.edtPrfTx {
   position: absolute;
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.edtPrfTx span {
   font-size: 13px;
   color: #ffffff;
   font-weight: 400;
}

.edtPrfTx svg {
   margin-top: 16px;
}

.ipFle {
   position: absolute;
   opacity: 0;
   width: 100%;
   left: 0px;
   pointer-events: none;
}

.edtPrfFrm label {
   font-size: 14px;
   color: #ffffff;
   font-weight: 400;
   line-height: normal;
}

.edtPrfFrm label>span {
   color: #FF2D2D;
}

.edtPrfFrm .form-control {
   background-color: #302F31;
   border-color: #302F31;
   border-radius: 0px;
   color: #ffffff;
   font-size: 14px;
   padding: 12.5px 16px;
   height: auto;
}

.edtPrfFrm .form-control::placeholder {
   color: #ffffff;
   opacity: 0.6;
}

.edtFrmSbm {
   text-align: center;
   padding: 14px 0px 0px;
}



.btnPrm {
   border-radius: 4px;
   background: linear-gradient(149deg, #FF724D 0%, #6D36E1 100%);
   border: 0px;
   color: #ffffff;
   font-weight: 400;

   &:hover {
      background: linear-gradient(-149deg, #FF724D 0%, #6D36E1 100%);
      color: #ffffff;
   }
}

.btn-12842 {
   min-width: 128px;
   font-size: 14px;
   padding: 10.5px 12px;
}

// Profile Edit Popup Content End
