/*--||
Common Use Start
||--*/

// @font-face {
//     font-family: 'Magenda';
//     src: url('../fonts/Magenda-Regular.eot') format('eot');
//     src: url('../fonts/Magenda-Regular.svg') format('svg');
//     src: url('../fonts/Magenda-Regular.woff') format('woff');
//     src: url('../fonts/Magenda-Regular.woff2') format('woff2');
//     font-weight: 400;
// }

@font-face {
    font-family: 'dinpro-regular';
    src: url('../fonts/dinpro-regular.eot') format('eot');
    src: url('../fonts/dinpro-regular.OTF') format('otf');
    src: url('../fonts/dinpro-regular.ttf') format('ttf');
    src: url('../fonts/dinpro-regular.woff') format('woff');
    src: url('../fonts/dinpro-regular.woff2') format('woff2');    
    font-weight: 400;
}
@font-face {
    font-family: 'dinpro-medium';
    src: url('../fonts/dinpro-medium.eot') format('eot');
    src: url('../fonts/dinpro-medium.OTF') format('otf');
    src: url('../fonts/dinpro-medium.woff') format('woff');
    src: url('../fonts/dinpro-medium.woff2') format('woff2');    
    font-weight: 500;
}
@font-face {
    font-family: 'dinpro-semibold';
    src: url('../fonts/dinpro-semibold.OTF') format('otf');
    src: url('../fonts/dinpro-semibold.woff') format('woff');
    src: url('../fonts/dinpro-semibold.woff2') format('woff2');
    src: url('../fonts/dinpro-semibold.eot') format('eot');
    font-weight: 600;
}
@font-face {
    font-family: 'dinpro-bold';
    src: url('../fonts/dinpro-bold.OTF') format('otf');
    src: url('../fonts/dinpro-bold.woff') format('woff');
    src: url('../fonts/dinpro-bold.woff2') format('woff2');
    src: url('../fonts/dinpro-bold.eot') format('eot');
    font-weight: 700;
}
@font-face {
    font-family: 'dinpro-extrabold';
    src: url('../fonts/dinpro-extrabold.OTF') format('otf');
    src: url('../fonts/dinpro-extrabold.woff') format('woff');
    src: url('../fonts/dinpro-extrabold.woff2') format('woff2');
    src: url('../fonts/dinpro-extrabold.eot') format('eot');
    font-weight: 800;
}
@font-face {
    font-family: 'dinpro-heavy';
    src: url('../fonts/dinpro-heavy.OTF') format('otf');
    src: url('../fonts/dinpro-heavy.woff') format('woff');
    src: url('../fonts/dinpro-heavy.woff2') format('woff2');
    src: url('../fonts/dinpro-heavy.eot') format('eot');
    font-weight: 900;
}

.pgCntr
{
    background-color: #000000;
    min-height: 100vh;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    // --font-Urbanist: 'Urbanist', sans-serif;
    // --font-magenda: 'Magenda';
    --font-dinproregular: 'dinpro-regular';
    --font-dinpromedium: 'dinpro-medium';
    // --font-dinpro-semibold: 'dinpro-semibold';
    // --font-dinpro-bold: 'dinpro-bold';
    // --font-dinpro-extrabold: 'dinpro-extrabold';
    // --font-dinpro-heavy: 'dinpro-heavy';
    // font-family: var(--font-magenda);
    font-family: var(--font-dinproregular);
    z-index: 0;
}

// @media(min-width:1350px){
//     .container1{
//         max-width: 1320px;
//     }
// }

.pgCntr *
{
    box-sizing: border-box;
}

img 
{
    max-width: 100%;
}

@media only screen and (min-width: 1200px)
{
    .pgCntr .container
    {
        max-width: 1170px;
    }
}

@media only screen and (min-width: 1350px){
    .pgCntr .container1 {
        max-width: 1320px;
    }
}

.btn:focus {
    box-shadow: none;
}

.pgCntr *:focus-visible
{
    outline: 0px;
}

@mixin df-ac {
	display: flex;
	align-items: center;
}

@mixin df-ac-jc {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin df-ac-jsb {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.transition {
	transition: .5s all;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

a,
a:hover {
	text-decoration: none;
}

.btn:focus,
.form-control:focus {
	box-shadow: none;
}

.btn,
.form-control {
	outline: 0;
}

.btn-Cmn-133-44{
    background: linear-gradient(
    90.47deg,
    rgba(255, 114, 77, 1) 0%,
    rgba(109, 54, 225, 1) 100%
  );
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-Urbanist);
  min-width: 133px;
  min-height: 44px;
  border: 0;
  &:hover{
    background: linear-gradient(91.37deg, #FF7C44 0%,  #9948FF 52.42%);
    color: #fff;
    @extend .transition;
  }
}

@media only screen and (max-width: 991px)
{
    .hdNavMenu {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 260px;
        height: 100vh;
        background-color: rgba(0, 0, 0, 1);
        transform: translateX(-100%);
        transition: 0.3s transform;
        z-index: 9999;
        overflow: auto;
    }

    .sbMenu .hdNavMenu {
        transform: translateX(0%);
    }
    
    .hdNavMenu .nav-link {
        margin: 8px 12px;
    }
}


/*--||
Common Use End
||--*/




/*- -- ============================================|| Home page content Start ||================================================= -- -*/



/*--||
Header Content Start
||--*/

.indHd {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 99999;    
    backdrop-filter: blur(5px);
    .navbar {
        padding: 0px 0px;
    }
    .navbar-brand{
        padding: 0px;
    }
    .hdNavMenu{
        ul.navbar-nav{
            li.nav-item{
                a.nav-link{                    
                    padding: 0 1.5rem;                    
                    span{
                        color: #ffffff;
                        font-size: 16px;
                        font-family: var(--font-Urbanist);
                        position: relative;
                        &:after{
                            position: absolute;
                            left: 0;
                            top: 25px;
                            content:'';
                            border-width: 0.7px 0 0 0;
                            border-style: solid;
                            border-image: var(
                                --linear,
                                linear-gradient(
                                90.47deg,
                                rgba(255, 114, 77, 1) 0%,
                                rgba(109, 54, 225, 1) 100%
                                )
                            );
                            border-image-slice: 1;    
                        }
                        &:hover:after, &.active:after{                                 
                           @extend .transition;
                           width: 100%;
                        }
                    }
                    
                }
            }
        }
        
    }
}


/* ----- Mobile Menu Toggle Start ----- */
@media only screen and (min-width: 992px)
{
    .mobMenu {
        
    }
}
.mobMenu {
    display: inline-block;
    vertical-align: middle;    
}

.smClose {
    width: 40px;
    height: 40px;
    z-index: 999;
    overflow: hidden;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
}
#closeicon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: scale(2) translate(0px, -1px);
    position: relative;
}
#closeicon path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: #ffffff;
    stroke-dashoffset: 0px;
}
#closeicon path#top,
#closeicon path#bottom {
    stroke-dasharray: 240px 950px;
}
#closeicon path#middle {
    stroke-dasharray: 240px 238px;
}

.smClose.active #closeicon  path#top,
.smClose.active  #closeicon path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
}
.smClose.active #closeicon  path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
}
/* ----- Mobile  Menu Toggle End  ----- */

/* Banner Section CSS Start */

.BnrDv{
    // background: url('../assets/images/banner.png') no-repeat;
    background: transparent;
    background-size: cover;
    min-height: 740px;
    .BnrDvCnt{
        text-align: center;
        padding-top: 150px;
        h2{
            font-size: 50px;
            background: linear-gradient(180deg, white 30%, rgba(255, 255, 255, 0) 70%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            display: inline-block;
            @media(max-width: 575px){
                font-size: 35px;
            }
        }
        h1{
            color: #FFF;
            font-size: 80px;
            font-family: var(--font-dinpromedium);
            @media(min-width:576px) and (max-width: 767px){
                font-size: 50px;
            }
            @media(max-width: 575px){
                font-size: 35px;
            }
        }
        p{
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            opacity:0.8;
            font-family: var(--font-dinpromedium);
            @media(max-width: 575px){
                font-size: 14px;
            }
        }
    }
}

/* Banner Section CSS End */

/* Events Section CSS Start */

.EvtsPlcsDv{
    background: #000;
    //padding: 25px 0;
    .EvtsPlcsDvCnt{
        .EvtsPlcsCntHd{
            h5{
                background: linear-gradient(91.37deg, #FF7C44 0%, #B91A4E 25.07%, #9948FF 52.42%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--font-Urbanist);
                margin-bottom: 0;
            }
        }
        .EvtsPlcsCntTbs{
            @include df-ac-jsb();
            flex-wrap: wrap;
            ul.nav-tabs{
                border-bottom: 0;
                li.nav-item{
                    &:first-child{
                        a{
                            padding-left: 0;
                        }

                    }
                    a.nav-link{
                        font-size: 50px;
                        color: #fff;
                        font-weight: 400;
                        opacity: 0.5;
                        &:hover, &.active{
                            background: transparent;
                            border-color: transparent;
                            opacity: 1;
                        }
                        @media(max-width: 767px){
                            font-size: 33px;
                        }
                    }
                }
            }
            a.SeAl{
                font-family: var(--font-Urbanist);
                font-size: 16px;
                color: #fff;
                margin-bottom: 0;
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    background: url('../assets/images/line.png');
                    left: 0;
                    width: 90%;
                    height: 1px;
                    top: 25px;
                }
            }
        }        
    }
    .EvtsPlcsTbCnts{
        .EvtsPlcsTbCntsDt{
            min-height: 160px;
            cursor: pointer;          
            @media(max-width:1199px){
                min-height: 140px;
            }
            &:nth-of-type(odd){
                background: #050408;
                h6{
                    color: rgba(255, 255, 255, 0.20);
                }
            }
            &:nth-of-type(even){
                background: #0a0710;
                h6{
                    color: rgba(255, 255, 255, 0.40);
                }
            }
            .EvtsPlcsIgDv{
                display: none;
            }
            p{
                font-size: 14px;
                color: rgba(255, 255, 255, 1);
                font-family: var(--font-Urbanist);
                margin-top: 1rem;
                display: none;
                span{
                    color: rgba(255, 255, 255, 0.50);
                }
            }
            .JmpBtn{
                display: none;
                background: linear-gradient(90.47deg, #ff724d 0%, #6d36e1 100%);
                border-radius: 4px;
                // display: flex;
                flex-direction: row;
                gap: 10px;
                // align-items: flex-start;
                // justify-content: flex-start;
                // align-items: center;
                color: #ffffff;
                text-align: center;
                font-size: 16px;
                font-family: var(--font-Urbanist);
                font-weight: 400;
                min-width: 100px;
                min-height: 43px;
                margin-top: 120px;
                margin-bottom: 1rem;
                border: 0;
                &:hover{
                    background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
                    color: #fff;
                    @extend .transition;

                }
                @media(max-width:992px){
                    margin-top: 32px;
                }
            }
            .EvtsPlcsTbCntsDtRw{
                padding: 32px 0;
                h6{                    
                    font-size: 40px;
                    margin-bottom: 0;
                    @media(max-width:1199px){
                        font-size: 30px;
                    }
                }
            }
           &:hover{
            background: linear-gradient(180deg, #171021 0%, #000 100%);
                .EvtsPlcsIgDv{
                    display: block;
                }
                h6{
                    color: rgba(255, 255, 255, 1);
                    transition: all 3s;
                }
                p{
                    display: block;
                }
                .JmpBtn{
                    display: block;
                }
           }
        }
    }
}

/* Events Section CSS End */

/* Whats New Section CSS Start */

.WhtsNwDv{
    background: url('../assets/images/whatsnew.svg') no-repeat;
    background-size: 100% 100%;  
    background-position: 100% 15%;
    padding: 100px 0;
    h4{        
        background: linear-gradient(91.37deg, #FF7C44 0%, #B91A4E 25.07%, #9948FF 52.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        font-family: var(--font-Urbanist);
        margin-bottom: 0;
        text-align: center;
        margin-bottom: 1rem;
    }
    h3{
        font-size: 50px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 1.5rem;
        @media(max-width: 1199px){
            font-size: 30px;
        }
    }
    .WhtsNwDvDts{
        margin-bottom: 1rem;
        position: relative;
        img{
            width: 100%;
        }
        .WhtsNwDvDtsCnts{
            @include df-ac-jsb();
            position: absolute;
            bottom: 25px;
            width: 100%;
            padding: 0 30px;
            flex-wrap: wrap;
            .WhtsNwDvDtsCntsL{
                p{
                    font-size: 14px;
                    color: rgba(255, 255, 255, 1);
                    font-family: var(--font-Urbanist);
                    text-align: left;
                    margin-bottom: 0.5rem;
                    span{
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
                h6{
                    font-size: 18px;
                    color: rgba(255, 255, 255, 1);
                    font-weight: 400;
                    font-family: var(--font-Urbanist);
                }                 
            }
            .JmpBtn{
                background: linear-gradient(90.47deg, #ff724d 0%, #6d36e1 100%);
                border-radius: 4px;
                color: #ffffff;
                text-align: center;
                font-size: 16px;
                font-family: var(--font-Urbanist);
                font-weight: 400;
                min-width: 100px;
                min-height: 43px;
                border: 0;
                &:hover{
                    background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
                    color: #fff;
                    @extend .transition;
                }
            }
              
        }
    }
    
}

/* Whats New Section CSS End */

/* Doubts Section CSS Start */

.DbtsDv{
    padding: 50px 0;
    h3{
        font-size: 50px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 0rem;
        padding: 30px 0;
        @media(max-width: 1199px){
            font-size: 30px;
        }
    }
    .accordion{
        border: 0;
        .accordion__item{
            border-bottom: 1px solid rgba(255, 255, 255, 0.20);
            padding: 20px 0;
            &:last-child{
                border-bottom: 0;
            }
            .accordion__heading{
                .accordion__button{
                    background: transparent;
                    font-size: 26px;
                    color: #fff;
                    padding: 0px;
                    font-family: var(--font-Urbanist);
                    @media(max-width:575px){
                        font-size: 18px;
                    }
                    &:before{
                        height: 8px;
                        width: 8px;
                        right: 18px;
                        position: absolute;
                        margin-top: 13px;
                        @media(max-width:575px){
                            margin-top: 8px;
                        }
                    }
                }
            }
            .accordion__panel{
                padding: 20px 0;
                p{
                    color: #DFDFDF;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 26px;
                    margin-bottom: 0;
                    font-family: var(--font-Urbanist);
                }
            }
        }
    }
}

/* Doubts Section CSS End */

/* Footer Section CSS Start */

.FtrTpDv{
    background: #110C18;
    padding: 30px 0;
    p{
        color: #DFDFDF;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;        
        font-family: var(--font-Urbanist);
        margin-top: 1rem;
    }
    h6{
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        font-family: var(--font-Urbanist);
    }
    span{
        color: rgba(255, 255, 255, 0.80);
        font-size: 13px;
        font-family: var(--font-Urbanist);
    }
    .FtrTpDvIp{
        @include df-ac();
        .form-control{
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.09);
            border-color: transparent;
            font-size: 13px;
            font-family: var(--font-Urbanist);
            font-weight: 500;
            height: 43px;
            color: rgba(255, 255, 255, 0.60);
            margin-right: 0.5rem;
        }
        .JmpBtn{
            background: linear-gradient(90.47deg, #ff724d 0%, #6d36e1 100%);
            border-radius: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            font-family: var(--font-Urbanist);
            font-weight: 400;
            min-width: 100px;
            min-height: 43px;
            border: 0;
            &:hover{
                background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
                color: #fff;
                @extend .transition;
            }
        }
    }    
}
.FtrBtmDv{
    background: #160F1E;
    padding: 50px 0 0;

    h5{
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        font-family: var(--font-Urbanist);
        position: relative;
        display: inline-block;
        margin-bottom: 3rem;
        &:after{
            content: '';
            position: absolute;
            background: url('../assets/images/footerline.png') no-repeat;
            left: 0;
            width: 100%;
            height: 1px;
            top: 30px; 
        }
    }
    ul li {
        margin-bottom: 1.5rem;
        a{
            color: #FFF;
            font-size: 15px;
            font-weight: 400;
            font-family: var(--font-Urbanist);
            &:hover{
                color: rgba(255, 255, 255, 0.60);
                @extend .transition;
            }
        }
    }
    .SmInDv{
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #261A33;
        border-radius: 50%;
        margin-right: 1.25rem;
        margin-bottom: 1rem;
        img{
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(200%) contrast(103%);
        }
        &:hover{
            transition: all .5s;
           img{
            filter: none;
           } 
        }
    }
}
.FtrCrDv{
    border-top:1px solid rgba(255, 255, 255, 0.20);
    padding: 1rem 0;
    p{
        font-family: var(--font-Urbanist);
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
        text-align: center;
    }
}

/* Footer Section CSS End */


/*--||
Create Section Start
||--*/
.crtSec {
    padding: 4rem 0px;
    font-family: "Urbanist", sans-serif;
}

.crtSecCnt {
    position: relative;
    padding: 0px 54px 52px;
    margin-bottom: 40px;
}

.crtSecCntBg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: calc(100% - 40px);
    border-radius: 10px;
    opacity: 0.85;
    background-color: #20172D;
}

.crtSecCon {
    position: relative;
}

.crtSecIg {
    margin-bottom: 46px;
}

.crtSecCon p {
    color: #ffffff;
    font-size: 14px;
    line-height: 26px;
}
@media only screen and (min-width: 1200px)
{
    .crtSecCon p {
        max-width: 82%;
    }
}

.crtSecLks {
    margin: -5px;
    padding-bottom: 1rem;
}

.crtSecLks .btn {
    margin: 5px;
}

.btnGr {
    border-radius: 4px;
    background: linear-gradient(149deg, #FF724D 0%, #6D36E1 100%);
    color: #ffffff;
    border: 0px;
    font-family: var(--font-Urbanist);
}

.btnGr:hover {
    background: linear-gradient(-149deg, #FF724D 0%, #6D36E1 100%);
    color: #ffffff;
}

.btn-14743 {
    min-width: 147px;
    font-weight: 600;
    padding: 9.5px 12px;
}

.btnBk {
    border-radius: 4px;
    background: #000000;
    color: #ffffff;
}

.btnBk:hover {
    background: #302f2f;
    color: #ffffff;
}


.vrDvc {
    position: absolute;
    top: 51%;
    right: -42%;
    transform: translate(0px, -50%);
    filter: blur(2px);
}


.crsOl {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.75;
    filter: blur(124px);
    z-index: -1;
}

@media only screen and (max-width: 991px)
{
    .vrDvc {
        position: relative;
        transform: none;
        top: 0%;
        left: 0%;
        filter: none;
    }
    
    .vrDvc img {
        max-width: 100%;
    }
    
    .crtSecTx {
        text-align: center;
    }
    
    .crtSecCnt {
        padding: 0px 44px 42px;
    }
}
@media only screen and (max-width: 767px)
{
    .crtSecIg {
        text-align: center;
        margin-bottom: 1.55rem;
    }
    
    .crtSecIg img {
        max-width: 75%;
    }
    
    .crtSecCnt {
        padding: 0px 34px 22px;
    }
    
    .crtSec {
        padding: 2rem 0px;
    }
    
    .crtSecTx svg {
        max-width: 50%;
        height: auto;
    }
}
@media only screen and (max-width: 575px)
{
    .crsOl img {
        max-width: 100%;
    }
    
    .crtSecCnt {
        padding: 0px 20px 20px;
    }
    
    .crtSecCon {
        text-align: center;
    }
}
@media only screen and (max-width: 399px)
{
    .btn-14743 {
        min-width: 127px;
        padding: 7.5px 12px;
    }
}
/*--||
Create Section End
||--*/



/*--||
Trending Items Section Start
||--*/
.trndItmsHd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.trndItmsHd h2 {
    color: #ffffff;
    font-size: 50px;
    line-height: 1.15;
    font-family: 'Magenda';
    position: relative;
    margin: 0px;
}

.btnTrndArw:focus
{
    box-shadow: none;
}

.btnTrndArw {
    padding: 0px;
}

.btnTrndArw.lt {
    margin-right: 12px;
}

.trndItmsLk {
    text-align: right;
    padding: 6px 0px 1rem;
}

.btn-17843 {
    min-width: 178px;
    font-weight: 600;
    padding: 9.5px 12px;
}

.trndItmR {
    /* display: flex; */
    margin: 0px -10px;
}

.trndItmC {
    padding: 10px;
    /* flex: 0 0 25%;
    max-width: 25%; */
}

.trndItmCd {
    border-radius: 19px;
    background-color: #242129;
    padding: 16px 8px 8px;
    position: relative;
}

.trndItmNm {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    margin: 0px 8px 1rem;
    position: relative;
    font-family: var(--font-Urbanist);
}
.trndItmNm.gr {
    background-image: linear-gradient( to right, rgba(255, 124, 68, 1), rgba(185, 26, 78, 1), rgba(153, 72, 255, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
}
.trndItmNm.or {
    color: #ff7c44;
}
.trndItmNm.pr {
    color: #9948ff;
}

.trndItmNmIg {
    margin-bottom: 1rem;
    text-align: center;
    position: relative;
    height: 228px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trndItmNmIg img {
    max-width: 100%;
}

.trndItmStk {
    text-align: right;
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: var(--font-Urbanist);
}

.trndItmStk span{
    color: #ffffff;
}

.trndItmDt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 20px;
    background: #1B1920;
    padding: 20.5px 16px 10px;
}

.trndItmDtLt
{
    margin-bottom: 10px;
    font-family: var(--font-Urbanist);
}

.trndItmDtLt h5 {
    font-weight: 600;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 2px;
    font-family: var(--font-Urbanist);
}

.trndItmDtLt h6 {
    color: rgba(255, 255, 255, 0.50);
    font-size: 13px;
    font-weight: 400;
    margin: 0px;
}

.trndItmPrc {
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1.2;
    font-family: var(--font-Urbanist);
}

.trndItmPrc span {
    background-image: linear-gradient(rgba(237, 128, 124, 1), rgba(153, 72, 255, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.trndItmCdCr {
    background: linear-gradient(180deg,rgba(209, 112, 146, 1) 0%,rgba(134, 38, 255, 1) 100%);
    border-radius: 50%;
    width: 144px;
    height: 144px;
    filter: blur(62px);
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, 0px);
}

.trndItms {
    padding: 5px 0px 2rem;
}
@media only screen and (max-width: 991px)
{
    .trndItmsHd h2
    {
        font-size: 44px;
    }
}
@media only screen and (max-width: 575px)
{
    .trndItmsHd h2
    {
        font-size: 38px;
    }

    .trndItmR
    {
        margin: 0px;
    }       
}
@media only screen and (max-width: 479px)
{
    .trndItmsHd h2
    {
        font-size: 30px;
    }

    .trndItmsLk
    {
        padding-top: 12px;
    }
}
// @media only screen and (max-width: 399px)
// {
//     .trndItmsArws {
//         margin-top: 10px;
//     }
// }
/*--||
Trending Items Section End
||--*/




/*--||
Rent and Sale Section Start
||--*/
.rnSlLndTx h2 {
    color: #ffffff;
    font-size: 50px;
    line-height: 1.15;
    font-family: 'Magenda';
    padding-bottom: 8px;
}

.rnSlLndTx h2 span {
    background-image: linear-gradient(    88deg,    rgba(255, 124, 68, 1) 0%,    rgba(153, 72, 255, 1) 100%  );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.rnSlLndTx p {
    color: #ffffff;
    font-size: 14px;
    line-height: 26px;
    font-family: var(--font-Urbanist);
}

.rnSlLndLks {
    margin: 0px -6px;
}

.btnGy {
    background-color: rgba(36, 33, 41, 1);
    color: #ffffff;
}

.btnGy:hover {
    background-color: #3e3e3e;
    color: #ffffff;
}

.btn-12643 {
    font-weight: 600;
    min-width: 126px;
    padding: 8.5px 12px;
}

.btn-15543 {
    min-width: 155px;
    font-weight: 600;
    padding: 9.5px 12px;
}

.rnSlLndLks .btn {
    margin: 6px 6px 0px;
}

.rnSlLnd {
    padding: 43px 0px 73px;
    margin-bottom: 33px;
}

.rnSlLndC
{
    padding-bottom: 40px;
}
@media only screen and (max-width: 991px)
{
    .rnSlLndR {
        flex-direction: column-reverse;
    }
    
    .rnSlLnd {
        margin-bottom: 0px;
    }

    .rnSlLndTx h2
    {
        font-size: 44px;
    }
}
@media only screen and (max-width: 575px)
{
    .rnSlLndTx h2
    {
        font-size: 38px;
    }
}
@media only screen and (max-width: 479px)
{
    .rnSlLndTx h2
    {
        font-size: 34px;
    }

    .rnSlLnd
    {
        padding-bottom: 53px;
    }
}
@media only screen and (max-width: 349px)
{
    .btn-12643 {
        font-size: 14px;
        min-width: 116px;
    }
    
    .btn-15543 {
        font-size: 14px;
        min-width: 145px;
    }
}
/*--||
Rent and Sale Section End
||--*/




/*--||
Join Our community Start
||--*/
.jnOuCmTl
{
    position: relative;
}
.jnOuCmTl h2
{
    color: #ffffff;
    font-size: 50px;
    line-height: 1.15;
    position: relative;
}

.jnOuCm {
    h4{
        font-size: 40px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin-bottom: 1.5rem;
        padding: 0 15px;
        @media(max-width:575px){
            font-size: 30px;
        }
        span{
            background-image: linear-gradient(88deg, #ff7c44 0%, #9948ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
        }
    }
    .btn-Cmn-StExplr{
        width: 155px;
        height: 43px;
        border-radius: 4px;
        background: var(--linear, linear-gradient(149deg, #FF724D 0%, #6D36E1 100%));
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        display: block;
        margin: auto;
        padding-top: 10px;
        border: 0;
        &:hover{
            background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
            color: #fff;
            @extend .transition;
        }
    }
}


.jnOuCmSldR
{
    display: flex;
    padding-top: 34px;
    padding-bottom: 34px;
}

.jnOuCmSldG1
{
    max-width: 368px;
}

.jnOuCmSldG1Ig img {
    height: 218px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.jnOuCmSldG1 > *:first-child {
    margin-bottom: 20px;
}

.jnOuCmSldG1Tx {
    background-color: rgba(32, 23, 45, 1);
    text-align: center;
    min-height: 218px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.jnOuCmSldG1Tx h2 {
    color: #ffffff;
    font-size: 40px;
    line-height: 60px;
    margin: 0px;
    background-image: linear-gradient(
        93.04deg,
        rgba(233, 105, 98, 1) 0%,
        rgba(129, 62, 204, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.jnOuCmSldG1Tx p {
    font-weight: 500;
    line-height: 26px;
    color: rgba(223, 223, 223, 1);
    margin: 0px;
    font-size: 14px;
}




.jnOuCmSldG2 {
    max-width: 562px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.jnOuCmSldG2Ig img {
    border-radius: 10px;
    transition: 0.3s transform;
    height: 456px;
    object-fit: cover;
}

.jnOuCmSldG2:hover .jnOuCmSldG2Ig img
{
    transform: scale(1.125);
}

.jnOuCmSldG2Tx {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
    background: linear-gradient(360deg, #1E1528 0%, rgba(0, 0, 0, 0.00) 100%);
    padding: 20px 34px 4px;
    color: #ffffff;
    opacity: 0;
    transition: 0.3s;
}

.jnOuCmSldG2:hover .jnOuCmSldG2Tx
{
    opacity: 1;
}

.jnOuCmSldG2Tx h6 {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.5;
}

.jnOuCmSldG2Tx p {
    font-weight: 500;
    line-height: 26px;
    color: #DFDFDF;
    font-size: 14px;
}

.jnOuCmSldCPd{
    padding: 0 10px;
}

// .jnOuCmSldC {
//     margin: 0px 10px;
// }

.crsOl2 {
    position: absolute;
    top: -49px;
    left: -20px;
    opacity: 0.75;
    filter: blur(124px);
    z-index: -1;
}
@media only screen and (max-width: 991px)
{
    .jnOuCmTl h2
    {
        font-size: 44px;
    }
}
@media only screen and (max-width: 767px)
{
    .jnOuCmSldG2Ig img {
        height: 356px;
    }
    
    .jnOuCmSldG2 {
        max-width: 462px;
    }
    
    .jnOuCmSldG1 {
        max-width: 318px;
    }
    
    .jnOuCmSldG1Ig img {
        height: 168px;
    }
    
    .jnOuCmSldG1Tx {
        min-height: 168px;
    }
    
    .jnOuCmSldG1Tx h2 {
        font-size: 30px;
        line-height: 36px;
    }
    
    .jnOuCmSldG2Tx {
        padding: 20px 24px 4px;
    }
}
@media only screen and (max-width: 575px)
{
    .crsOl2
    {
        width: 100%;
    }
    .crsOl2 img
    {
        max-width: 100%;
    }

    .jnOuCmTl h2 {
        font-size: 38px;
    }
}
@media only screen and (max-width: 479px)
{
    .jnOuCmSldG1 > *:first-child {
        margin-bottom: 10px;
    }

    .jnOuCmSldCPd {
        padding: 0px 5px;
    }
    
    // .jnOuCmSldC {
    //     margin: 0px 5px;
    // }
    
    .jnOuCmSldG2 {
        max-width: 372px;
    }
    
    .jnOuCmSldG2Ig img {
        height: 296px;
    }
    
    .jnOuCmSldG2Tx h6 {
        font-size: 18px;
    }
    
    .jnOuCmSldG2Tx p {
        font-size: 13px;
    }
    
    .jnOuCmSldG2Tx {
        padding: 20px 15px 4px;
    }
    
    .jnOuCmSldG1Ig img {
        height: 148px;
    }
    
    .jnOuCmSldG1Tx {
        min-height: 137px;
    }
    
    .jnOuCmTl h2
    {
        font-size: 34px;
    }

    .jnOuCmSldG1Tx h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .jnOuCmSldR
    {
        padding-top: 24px;
    }
}
/*--||
Join Our community End
||--*/

.trndItmsArws .btnTrndArw.rt{
    position: absolute;
    right: 20px;
    top: -110px;
    left: auto;
}
.trndItmsArws .btnTrndArw.lt{
    position: absolute;
    right: 60px;
    top: -110px;
    left: auto;
}


.mainAppContent{
    min-height: calc(100% - 58px);    
    padding-top: 60px;
}

.top-head-plot-book{
    border-radius: 5px;
    border: 1px solid #201B37;
    background: #21212E;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    padding: 15px 60px;
    text-align: center;
    margin-bottom: -25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;    
    @media (max-width: 767px) {
        font-size: 16px;
        padding: 15px;
    }
}

.txt-allOvIndia{
    font-size: 26px;
    font-weight: 600;
    @media (max-width: 767px) {
        font-size: 18px;        
    }
}

.plot-map{
    svg{
        max-width: 1057px;
        max-height: 614px;
        width: 100%;
        
       g{
        position: relative;
        path:not([fill^="url"]){           
            fill: transparent;
        }
            g{
            circle{
                fill: transparent;
            }            
        }
            &:hover{
            path:not([fill^="url"]){           
                // fill: transparent;
                // &:hover{
                    fill: #545461;    
                    & +  g{
                        circle{
                            &:nth-child(1){
                                fill: #21212E;
                            }
                            &:nth-child(2){
                                fill: #F86464;
                            }
                        }
                    }           
               // }
            }
        }
            
     }
      
    }
}


.tooltip{
    .tooltip-inner{
        border-radius: 6px;
        padding: 8px 20px;
        background: rgba(17, 64, 255, 0.60);
    }
    .arrow::before{
        border-top-color: rgba(17, 64, 255, 0.60);
    }
}
/*--||
Token Lock & Utility Start
||--*/
.tknLkCon
{
    background-image: url('/assets/images/lockBg.png');
    background-size: cover;
    border-radius: 29px;
    max-width: 757px;
    margin: 0px auto;
    padding: 64px 66px 55px 66px;
    text-align: center;
}
.tknLkCon h2
{
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 4px;
}
.tknUlCon h3.heading
{
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    font-family: var(--font-dinpromedium);
}
.tknLkCon p
{
    font-weight: 400;
    line-height: 28px;
    color: rgba(255,255,255,0.8);
    margin: 0px;
}


.tknLkUl {
    padding: 52px 0px;
    background-color: #000000;
    position: relative;
}

.tknLk {
    padding: 52px 0px;
}


.tknUl h2 {
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 4px;
    text-align: center;
}

.tknUlIc {
    text-align: center;
}

.tknUlCr {
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    background-image: linear-gradient(135deg, #FF724D 0%, #E541A8 47.92%, #6D36E1 100%);
    width: 146px;
    height: 146px;
    padding: 2px;
    margin-bottom: 10px;
}

.tknUlCrIn {
    background-color: #0E0A14;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tknUlIcSd {
    width: 127px;
    height: 19px;
    border-radius: 127px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #451F96 0%, rgba(0, 0, 0, 0.00) 100%);
    margin: 0 auto 10px;
}

.tknUlCon p {
    font-weight: 400;
    line-height: 28px;
    color: rgba(255,255,255,0.8);
    margin: 0px;
}

.tknUlCon {
    text-align: center;
}

.tknUlC {
    padding-top: 16px;
    padding-bottom: 16px;
}

.tknUl {
    padding: 60px 0px;
    padding-bottom:40px;
}

.tknUlR {
    padding: 54px 0px 0px;
    @media only screen and (max-width: 575px)
    {
        padding-top: 24px;
    }
}
/*--||
Token Lock & Utility End
||--*/
/*MS Style*/
.gradientTxt{
    background: linear-gradient(91.37deg, #FF724D 0%, #6D36E1 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.homeVideoCnt{
    position: relative;

    .container{
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 46.35%, #000 100%), url('/assets/images/parklandHomeVideo.gif'), lightgray 50% / cover no-repeat;
        background-size:cover;
        z-index: 1;
    }
    img{
        width:100%;
    }
}
.homeVideoContainer{
    position:relative;

    &:after{
        content:'';
        position: absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 46.35%, #000 100%), url('/assets/images/parklandHomeVideo.gif'), lightgray 50% / cover no-repeat;
        background-size:cover;
        z-index: 1;
    }

    video{
        width:100%;
    }
}
.homeVideoTxtCnt{
    position: relative;
    z-index: 2;
    margin-top:-90px;
    h3{
        &.secHeading{
            color: #FFF;
            font-size: 50px;
            line-height: 60px;
            font-family:var(--font-dinpromedium);
        }
    }

    ul{
        padding-left:1rem;
        li{
            list-style-type: disc;
            color: #FFF;
            font-size: 16px;
            font-family:var(--font-dinpromedium);
            font-weight: 500;
            line-height: 28px;
            opacity: 0.8;
        }
    }
}
.parklandExploreSec{
    padding: 50px 0;
    .innerCnt{
        max-width:75%;
        padding:15px;

        @media(min-width:1500px){
            max-width:50%;
        }
        @media(max-width:1199px){
            max-width:100%;
        }
        
    }
    h3{
        &.heading{
            font-size: 70px;
            font-family:var(--font-dinpromedium);
            font-weight: 500;
            line-height: 80px;

            @media(max-width:1199px){
                font-size: 60px;
                line-height: 70px;
            }
            @media(max-width:991px){
                font-size: 50px;
                line-height: 60px;
            }
            @media(max-width:767px){
                font-size: 40px;
                line-height: 50px;
            }
            @media(max-width:575px){
                font-size: 30px;
                line-height: 40px;
            }
        }
    }
    p{
        color: #FFF;
        font-size: 16px;
        font-family:var(--font-dinpromedium);
        font-weight: 500;
        line-height: 28px;
        opacity:0.8;
    }
    .exprBannerImg{
        width:100%;
    }
}
.top {
    span {
        font-size: 20px;
        padding-top: 45px;
        padding-bottom: 25px;
        line-height:30px;

        @media(min-width:768px){
            min-height: 130px;
            padding-bottom: 0px;
        }
        @media(min-width:992px){
            padding-bottom: 15px;
        }
        @media(min-width:1200px){
            min-height: 130px;
            padding-bottom: 0px;
        }
    }
}

.top-circle {
    clip-path: circle(50% at 50% 100%);
    display: block;
    height: 206px;
    margin-top: -90px;
    pointer-events: none;
}

.gradient {
    background: var(--linear, linear-gradient(93deg, #FF724D 0%, #6D36E1 67%));
}

.circle-black {
    background: #101010;
}

.parent {
    filter: url('#goo');
    overflow: hidden;
    z-index: 1;
    span {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #FFF;
    }
    label {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        color: #FFF;
        position: absolute;
        // bottom: 15px;
        /* margin: 0 auto; */
        // right: 0;
        // left: 0;
        margin: 0px;
        margin-top: 8px;
    }
    .top-circle label {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.bottom {
    span {
        font-size: 20px;
        padding-top: 45px;
        padding-bottom: 25px;
        line-height:30px;
        min-height: 130px;

        @media(min-width:768px){
            padding-top: 0px;
        }
        @media(min-width:992px){
            padding-top: 0px;
        }
    }
    .bottom-circle {
        clip-path: circle(50% at 50% 0%);
        display: block;
        height: 206px;
        pointer-events: none;
        margin-bottom: -103px;
    }
    .parent {
        filter: url('#goo');
        overflow: hidden;
        z-index: 1;
        span {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            color: #FFF;
        }
        label {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            color: #FFF;
            position: absolute;
            /* margin: 0 auto; */            
            bottom: 50%;
            left: 50%;
            transform: translate(-50%,50%);
            margin: 0px 0px 12px;
        }
    }
}

@media only screen and (max-width: 1199px)
{
    .top-circle
    {
        display: inline-block;
        min-width: 204px;
        label
        {
            margin-top: 2px;
        }
    }

    .bottom
    {
        .bottom-circle
        {
            display: inline-block;
            min-width: 204px;
            label
            {
                margin: 0px 0px 16px;
            }
        }
    }
}

@media (max-width: 767px) {
    .top-circle {
        height: 219px;
    }
}

@media (min-width: 1200px) {
    .tokenomics-block .col-xl-5 {
        flex: 1 0 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 575px)
{
    .parent label,
    .bottom .parent label
    {
        font-size: 24px;
    }

    .top-circle label {
        margin-top: 6px;
    }

    .top span
    {
        padding-bottom: 0px;
    }
    .bottom span
    {
        min-height: initial;
        padding-top: 5px;
    }
}
/*E O MS Style*/

.plotMap
{
   svg
   {
      .stateMask,
      .MapSideGr
      {
         pointer-events: none;
      }
      g
      {
         a:hover
         {
            .stateMask
            {
               opacity: 1;
            }
         }
      }
      *:focus
      {
         outline: 0px;
      }
   }
}

.tooltip
{
   pointer-events: none;
}
.tooltip[x-placement^=right] .arrow::before
{
   border-right-color: #351a6e !important;
}
.tooltip[x-placement^=left] .arrow::before
{
   border-left-color: #351a6e !important;
}
.tooltip[x-placement^=bottom] .arrow::before
{
   border-bottom-color: #351a6e !important;
}
.tooltip[x-placement^=top] .arrow::before
{
   border-top-color: #351a6e !important;
}
.tooltip-inner
{
   background-color: #351a6e !important;
}

/**/

.AbtPtclPrkLdsMV{
    padding: 70px 0;
    h3{
        color: #FFF;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
        margin-bottom: 24px;
        @media(max-width: 575px){
            font-size: 35px;
            line-height: 45px;
        }
    }
    p{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        opacity: 0.800000011920929;
    }
    .hrline{
        margin-bottom: 20px;
    }
    .ActUsr{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .ActUsrL{
            h4{
                color: #FFF;
                text-align: center;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @media(max-width: 575px){
                    font-size: 35px;
                }
            }
            h5{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                opacity: 0.800000011920929;
            }
        }
        .ActUsrR{            
            h6{
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }
            .Ptcpts{
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.PtcptsML{
                    margin-left: -10px;
                }
                &.PtcptsMLR{
                    background: #45404a;
                    border-radius: 50%;
                    margin-left: -10px;
                    span{                        
                        color: #FFF;
                        text-align: center;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 10px;
                    }
                }
            }
        }
    }
}

.TknLkDv{
    border-radius: 29px;
    background: #0E0A14;
    backdrop-filter: blur(77px);
    padding: 0 50px;
    @media(max-width: 991px){
        padding: 20px 50px;
    }

    h3{
        color: #FFF;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1rem;
    }
    p{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 0;
        @media(max-width: 991px){
            margin-bottom: 32px;
        }
    }
}

.ErnOptunsDv{
    padding: 70px 0 0;
    h3{
        color: #FFF;
        text-align: center;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 60px;
        @media(max-width: 575px){
            font-size: 35px;
            margin-bottom: 30px;
        }
    }
    .ErnOptunsDvCnt{
        min-height: 386px;
        background-color: #0E0A14 !important;
        padding: 24px;
        position: relative;
        margin-bottom: 1rem;
        &.ErnOptunsDvCnt1{
            background: url('../assets/images/earn1.png') no-repeat;           
            background-position: 85% 57%;
        } 
        &.ErnOptunsDvCnt2{
            background: url('../assets/images/earn2.png') no-repeat;           
            background-position: 85% 57%;
        }
        &.ErnOptunsDvCnt3{
            background: url('../assets/images/earn3.png') no-repeat;           
            background-position: 85% 57%;
        }
        .ErnOptunsDvCntspn{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            span{
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }       
        h4{
            color: #FFF;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 90px;
        }
        h5{
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 40px;
        }
        p{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }        
    }
    @media only screen and (max-width: 575px)
    {
        padding-top: 40px;
    }
}
.PkldsRdMp{
    background: url('../assets/images/roadmap.png') no-repeat;
        
    h3{
        color: #FFF;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        text-align: center;
        margin-bottom: 22x;
        @media(max-width: 575px){
            font-size: 35px;
            margin-bottom: 30px;
        }
    }
    p{
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        width: 75%;
        margin: auto;
        @media(max-width: 991px){
            width: 100%;
        }
    }
}




.timeline {
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 auto 1rem auto;
    overflow: hidden;
    margin-top: 48px;
}
.timeline:after {
    content: "";
    position: absolute;
    top: 140px;
    left: 50%;
    margin-left: -2px;
    background: linear-gradient(149deg, #6333C9 0%, #FF724D 100%);
    width: 1px;
    height:calc(100% - 300px);
    opacity: 0.5;
    display: block;
    @media(min-width:992px) and (max-width: 1199px){
        top:180px;
        height: 91%;
    }
}

.timeline-row {
    padding-left: 50%;
    position: relative;
    margin-bottom: 30px;    
}

.timeline-row .timeline-time {
    position: absolute;
    right: 42%;
    top: 45%;
    text-align: center;
    margin-right: 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    min-width: 170px;
}
.timeline-row .timeline-content {
    position: relative;
    padding: 1rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 245px;
}
// .timeline-row .timeline-content.tl1{
//     background: url('../assets/images/tl1.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl2{
//     background: url('../assets/images/tl2.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl3{
//     background: url('../assets/images/tl3.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl4{
//     background: url('../assets/images/tl4.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl5{
//     background: url('../assets/images/tl5.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl6{
//     background: url('../assets/images/tl6.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl7{
//     background: url('../assets/images/tl7.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl8{
//     background: url('../assets/images/tl8.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl9{
//     background: url('../assets/images/tl9.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl10{
//     background: url('../assets/images/tl10.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl11{
//     background: url('../assets/images/tl1.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl12{
//     background: url('../assets/images/tl12.png') no-repeat;
//     background-size: cover;
// }
// .timeline-row .timeline-content.tl13{
//     background: url('../assets/images/tl13.png') no-repeat;
//     background-size: cover;
// }
.TmlnBg img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}
.timeline-row .timeline-content:before {
    content: "";
    position: absolute;
    top: 45%;
    right: -112px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 10;
    background: url('../assets/images/roadmapicon.png');
    width: 24px;
    height: 46px;
}
.timeline-row .timeline-content p {
    margin: 0;    
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

}
.timeline-row:nth-child(even) .timeline-content {
    margin-left: 100px;
    text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:before {
    left: -112px;
    right: initial;
}
.timeline-row:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
    right: auto;
    left: 39%;
    text-align: center;
    margin-right: 0;
    margin-left: 0px;
    transform: rotate(90deg);
    @media(min-width:992px) and (max-width: 1199px){
        left: 36%;
    }
    @media(max-width:991px){
        transform: rotate(0deg);
        text-align: left;
    }
}
.timeline-row:nth-child(odd) .timeline-content {
    margin-right: 100px;
}
.timeline-row:nth-child(even) .timeline-time {
    left: auto;
    right: 38%;
    text-align: center;
    margin-right: 0;
    margin-left: 0px;
    transform: rotate(90deg);
    @media(min-width:992px) and (max-width: 1199px){
        right: 36%;
    }
    @media(max-width:991px){
        transform: rotate(0deg);
    }
}

@media (max-width: 992px) {
    .timeline {
        padding: 15px;
    }
    .timeline:after {
       display: none;
    }
    .timeline .timeline-row:nth-child(odd) {
        padding: 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-time {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content {
        margin: 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content:before {
        display: none;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content:after {
        display: none;
    }
    .timeline .timeline-row:nth-child(even) {
        padding: 0;
    }
    .timeline .timeline-row:nth-child(even) .timeline-time {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
        text-align: left;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content {
        margin: 0;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content:before {
        display: none;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content:after {
        display: none;
    }
}

.parkLandHomeMapCnt{
    padding: 25px 0;

    .btnGr{
        &.btn-14743{
            min-width: 210px;
        }
    }

    .plotMap
    {
        svg
        {
            @media only screen and (max-width: 991px)
            {
                width: 100%;
                height: auto
            }
        }
    }
}

.ByNwPg{
    margin: 150px 0 50px;
    .ByNwPgDv{
        .ByNwPgDvL{
            padding: 70px 57px;
            background: linear-gradient(0deg, #121113 0%, #121113 100%);            
        }
        .ByNwPgDvR{
            padding-bottom: 24px;
            border-bottom: 1px solid #211D25;           
            .ByNwPgDvRId{
                background: linear-gradient(90deg, #AE4226 0%, rgba(82, 0, 255, 0.00) 100%);
                min-width: 82px;
                height: 22px;
                display: inline-block;
                padding: 0 10px;
                @media(max-width:991px){
                    margin-top: 15px;
                }
                span{
                    color: rgba(255, 255, 255, 0.80);
                    text-align: center;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
            .ByNwPgDvROnBy{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                .ldv{
                    h4{
                        color: #FFF;
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 26px;
                        margin-bottom: 16px;
                        margin-top: 11px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.80);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
                .rdv{
                    h6{
                        color: rgba(255, 255, 255, 0.80);
                        text-align: right;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        margin-bottom: 14px;
                    }
                    h5{
                        color: #FFF;
                        text-align: right;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 600;
                        margin-bottom: 10px;

                    }
                    .rdvcpy{
                        background: #121113;
                        min-width: 165px;
                        height: 28px;
                        padding: 10px;
                    }
                    span{
                        color: rgba(255, 255, 255, 0.70);
                        text-align: right;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }
        }
        .ByNwPgDvPrcDv{
            margin-top: 15px;
            margin-bottom: 15px;
            .ByNwPgDvPrcDvL{
                h6{
                    color: rgba(255, 255, 255, 0.80);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 12px;
                }
                h3{
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    span{
                        color: rgba(255, 255, 255, 0.70);
                    }
                }
                h5{
                    color: rgba(255, 255, 255, 0.74);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
            .ByNwPgDvPrcDvR{
                .buynow-btn{
                    border-radius: 4px;
                    background: linear-gradient(149deg, #FF724D 0%, #6D36E1 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 110px;
                    min-height: 44px;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    border:0;
                    &:hover{
                        background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
                        transition: all .5s;
                    }
                }
            }
        }
        .DtsActTbs{
            ul.nav-tabs{
                background: #121113;
                display: inline-flex;
                border-bottom: 0;
                min-width: 174px;
                min-height: 47px;
                li{
                    width: 50%;
                    padding: 5px 5px;
                    a{                        
                        background-color: transparent;
                        border-radius: 2px;
                        cursor: pointer;border: none;
                        span{
                            color: #FFF;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                        }
                        &.active, &:hover{                            
                            background-color: #000;       
                            transition: all .5s;                     
                            span{
                                background: var(--linear, linear-gradient(149deg, #FF724D 0%, #6D36E1 100%));
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
            .DtsActTbsCt{
                background: linear-gradient(0deg, #121113 0%, #121113 100%);
                padding: 19px 16px;
                margin-top: 10px;
                label{
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                p{
                    color: #FFF;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    margin-bottom: 20px;
                    line-height: 26px; 
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                table{
                    thead th{
                        border:0px;
                        padding: 0.25rem 0.75rem;
                        color: #959595;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                    }
                    tbody{
                        tr{
                            td{
                                padding: 0.25rem 0.75rem;
                                white-space: nowrap;
                                border:0;
                                p{
                                    color: #FFF;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 26px;
                                    margin-bottom: 0;
                                }
                                span{
                                    color: #FFF;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 26px;
                                    opacity: 0.699999988079071;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .RltdPlts{
        margin-top: 67px;
        justify-content: center;
        h3{
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; 
            margin-bottom: 30px;            
        }
        .RltdPltsDv{
            background: #121113;
            padding: 20px;
            margin-bottom: 1rem;
            .ByNwPgDvRId{
                background: linear-gradient(90deg, #AE4226 0%, rgba(82, 0, 255, 0.00) 100%);
                min-width: 82px;
                height: 22px;
                display: inline-block;
                padding: 0 10px;
                margin-bottom: 10px;
                span{
                    color: rgba(255, 255, 255, 0.80);
                    text-align: center;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                }
            }  
            .pltsdtsImg{
                width: 100%;
                object-fit: cover;
            }
            .RltdPltsDvDts{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 20px;
                .RltdPltsDvDtsL, .RltdPltsDvDtsR{
                    h5{
                        color: #FFF;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px;
                        margin-bottom: 0;
                        span{
                            color: rgba(255, 255, 255, 0.70);                            
                        }
                    }
                    p{
                        color: rgba(255, 255, 255, 0.70);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;                        
                    }                    
                }
                .buynow-btn{
                    border-radius: 4px;
                    background: linear-gradient(149deg, #FF724D 0%, #6D36E1 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    min-height: 44px;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    border:0;
                    &:hover{
                        background: linear-gradient(91.37deg, #FF7C44 0%, #9948FF 52.42%);
                        transition: all .5s;
                    }
                }
                .buynow-btn.UlstdBtn{
                    background: #231E2B;
                }
            } 
        }
    }
    .Crdnts{
        margin-top: 67px;
        h3{
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; 
            margin-bottom: 30px;            
        }
        .CrdntsCnt{
            background: #121113;
            padding: 20px;
            margin-bottom: 1rem;
            h5{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px
            }
            p{
                color: #FFF;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                padding-left: 28px;
                margin-bottom: 0;
            }
        }       
    }
}
@media only screen and (max-width: 575px)
{
    .BnrDv
    {
        min-height: 510px;
        .BnrDvCnt
        {
            padding-bottom: 90px;
        }
    }
}




// Header Profile Dropdown Start
.hdrAdrDd .hdrAdrDdBtn.btn {
    background-color: #000000;
    border-color: #000000;
    padding: 6px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 0px;
}

.hdrAdrDd .hdrAdrDdBtn.btn img {
    margin-right: 3px;
}

.hdrAdrDd .hdrAdrDdBtn.btn::after {
    vertical-align: middle;
    margin-left: 7px;
    border: 0px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='white' stroke-opacity='0.7' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 10px;
    height: 6px;
}

.hdrAdrDd .hdrAdrDdBtn.btn:focus {
    background-color: #000000;
    box-shadow: none;
}

.hdrAdrDd .dropdown-menu {
    width: 100%;
    background-color: #0B0B0B;
    padding: 0px;
}

.hdrAdrDd .dropdown-menu .dropdown-item {
    padding: 10px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
}

.hdrAdrDd .dropdown-menu .dropdown-item img {
    margin-right: 7px;
    vertical-align: middle;
}

.hdrAdrDd .dropdown-menu .dropdown-item:hover {
    background-color: #000000;
}
@media only screen and (max-width: 349px)
{
    .hdrAdrDd .hdrAdrDdBtn.btn span {
        max-width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
}
// Header Profile Dropdown End




// Wallet Connect Modal Start
@media only screen and (min-width: 576px)
{
    .walConMdl {
        max-width: 412px;
    }
}

.walConMdl .modal-content 
{
    background-color: rgba(18, 17, 19, 0.97);
    border-radius: 0px;
}


.walConBtns {
    max-width: 296px;
    margin: 0 auto;
    padding: 24px 0px 12px;
    @media only screen and (max-width: 399px)
    {
        padding: 14px 0px 2px;
    }
}

.walConBtns .btn {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    background-color: #000000;
    margin-bottom: 10px;
    border-style: solid;    
    border-image-slice: 1;
    border-width: 1px;
}

.walConBtns .btn:hover
{
    border-image: linear-gradient( 180deg, rgba(139, 85, 255, 0.7) 0%, rgba(255, 114, 77, 0.7) 100% );
    border-image-slice: 1;
}


.walTypeBtn {
    min-width: 38px;
    text-align: center;
    margin-right: 10px;
}

.btnWalCls {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9;
}

.walCon h6 {
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}

.walCon {
    padding: 20px 0px 0px;
}


.walConMdlBkDp {
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51));
    backdrop-filter: blur(3.5px);
}

.walConMdlBkDp.show {
    opacity: 0.75;
}
// Wallet Connect Modal End


.sectit {
    
        font-size: 26px;
    color: #fff;
    padding: 0px;
    font-family: var(--font-Urbanist);
    
    .metapark-txt{

font-size: 26px;
    color: #fff;
    padding: 0px;
    font-family: var(--font-Urbanist);
}
}